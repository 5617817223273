.submissionWrapper{
  margin: 30px 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  @media screen and (min-width: 775px) {
    max-width: 742px;
    margin: 30px auto;
  }
}

.linkButton{
  background-color: #001C77;
  border-radius: 8px;
  border: 1px solid #001C77;
  padding: 16px;
  max-width: 251px;
  margin: 24px auto;
}

.unqualifiedLink{
  color: #FFFFFF !important;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
}