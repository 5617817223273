.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin: 0 24px 24px;
  padding: 16px 24px;
  @media screen and (min-width: 426px) {
    margin: 0 auto 24px;
  }
}

.title {
  font-size: 36px;
  font-weight: 600;
  justify-content: center;
  display: flex;
  text-align: center;
  color: #101828;
  margin: 40px auto 20px;
  max-width: 400px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 605px) {
    flex-direction: row;
  }
}

.selectWrapper {
  @media screen and (min-width: 605px) {
    margin-right: 12px;
  }
}

.input {
  margin: 0 12px 16px 0px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.label {
  margin-bottom: 4px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 600;
}

.label.installation{
  margin-left: 12px;
}

.radioGroup {
  margin: 0 12px 16px 0px;
  display: flex;
  flex-direction: column;
}

.radioOptions {
  margin-top: 8px;
}

.radioColumn {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
}

.radioInput {
  margin-right: 4px;
  cursor: pointer;
}

.radioLabel {
  margin-right: 8px;
}

.terms {
  font-size: 14px;
  margin: 24px 0 16px;
  max-width: 350px;
}

.inputField {
  padding: 8px;
  font-size: 16px;
  border: 2px solid #d0d5dd;
  border-radius: 5px;
  justify-content: right;
}

.inputGroup {
  display: flex;
  margin: 0 auto 20px;
  flex-direction: column;
}

.selectField > div {
  border: 2px solid #d0d5dd;
  border-radius: 5px;
}

.termsBox{
  margin: 0 auto;
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
}

.checkboxField {
  min-height: 20px;
  min-width: 20px;
  margin: 0 12px 0 0;
  cursor: pointer;
}

.submitButton {
  justify-content: center;
  margin: 16px auto 0;
  background-color: #00165E;
  padding: 16px 64px;
  border-radius: 10px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
}

.link {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.errorMessage {
  color: #f04438;
  margin: 8px 8px 0 0;
  font-size: 14px;
}

.switchWrapper{
  display: flex;
  flex-direction: row;
  margin: 0 0 16px 12px;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  min-width: 44px;
  height: 24px;
  margin-right: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .3s;
  transition: .3s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #00C6C1;
  ;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00C6C1;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.addressWarning{
  color: #475467;
}

.submissionError {
  margin: 16px auto 0;
}