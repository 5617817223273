.navbar {
  background: rgb(3, 201, 194);
  background: linear-gradient(
    90deg,
    rgba(3, 201, 194, 1) 0%,
    rgba(0, 28, 119, 1) 100%
  );
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
  z-index: 2;
}

.navbar-logo {
  margin: 20px auto;
  cursor: pointer;
}
